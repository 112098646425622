$border-color: #d4d4d4;

.threewaytoggle {
	letter-spacing: 0.2px;
	&__switch {
		display: inline-block;
	    background: white;
	    transition: background 0.2s;
	    cursor: pointer;
	}
	&__switch:hover {
		background: #eee;
	}
	&__before {
		padding: 5px 10px 5px 15px;
	    border: 1px solid $border-color;
	    border-top-left-radius: 20px;
	    border-bottom-left-radius: 20px;
	    border-right: 1px solid #dedede;
	    
	}
	&__during {
		padding: 5px 10px 5px 15px;
	    border-top: 1px solid $border-color;
	    border-bottom: 1px solid $border-color;
	    border-right: 1px solid #dedede;
	}
	&__after {
		padding: 5px 15px 5px 13px;
	    border: 1px solid $border-color;
	    border-top-right-radius: 20px;
	    border-bottom-right-radius: 20px;
	}
	&__selected {
		background: #61af61;
		color: white;
	}
	&__selected:hover {
		background: #61af61;
	}
}