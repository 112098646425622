.commonbutton {
    padding: 13px;
    border-radius: 42px;
    cursor: pointer;
    text-align: center;
    transition: box-shadow 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, background-color 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, border-color 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, filter 250ms ease 0s;
}

.commonbutton-phone {
    text-decoration: none !important;
}

.greenbutton {
	background-color: #2e7b4b;
	color: white;
    a {
        text-decoration: none !important;
        color: white;
    }
}

.greenbutton:hover {
	background-color: #198a47;
}

.greenbutton:active {
	box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px;
    // transform: translateY(1px);
    filter: brightness(0.8) !important;
}

.greenbutton-disabled {
    background-color: #7cab8c;
    color: white;
    cursor: not-allowed;
    a {
        text-decoration: none !important;
        color: white;
    }
}

.whitebutton {
	background-color: #F2F2F2;
	color: #404040;
    a {
        text-decoration: none !important;
        color: #404040;
    }
}

.whitebutton:hover {
	background-color: #d8d8d8;
}

.whitebutton:active {
	 box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px;
    // transform: translateY(2px);
    filter: brightness(0.8) !important;
}

.yellowbutton {
    background-color: #dca71f;
    color: white;
}

.yellowbutton:hover {
    background-color: #e2b540;
}

.yellowbutton:active {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px;
    // transform: translateY(2px);
    filter: brightness(0.9) !important;
}

.whitebuttonloading {
    background-color: #d8d8d8;
    padding-left: 20px;
    padding-right: 20px;
    color: #404040;
    cursor: not-allowed;
    .glyphicon {
        margin-right: 6px;
    }
}

.greenbuttonloading {
    background-color: #66a07c;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    cursor: not-allowed;
    .glyphicon {
        margin-right: 6px;
    }
}