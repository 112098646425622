.thirdsection {
    background-color: #627d6e;
    width: 100%;
    background-size: 10px 10px;
    background-image: linear-gradient(to right, #6e8a7b 1px, transparent 1px), linear-gradient(to bottom, #6e8a7b 1px, transparent 1px);
    padding-top: 80px;

    &__stars {
    	color: white;
    	font-size: 20px;
    	letter-spacing: 10px;
    	text-align: center;
    }

    &__header {
    	h1 {
    		color: white;
    		font-size: 30px;
    		text-align: center;
    		font-weight: 900;
    		letter-spacing: 0.5px;
    		@media only screen and (max-width: 770px) {
				font-size: 25px;
			}
    	}
    	@media only screen and (max-width: 770px) {
			padding-left: 40px;
			padding-right: 40px;
		}
    }

    &__ratings {
    	margin: 0 auto;
    	max-width: 540px;
    	padding-top: 30px;

    	@media only screen and (max-width: 767px) {
			max-width: 300px;
			.googlerating {
				padding-top: 30px;
			}
		}

		@media only screen and (min-width: 771px) {
			.rating {
				padding-left: 30px;
    			padding-right: 30px;
			}	
		}
        @media (max-width: 830px){
            .row {
                margin-right: 0px;  
                margin-left: 0px;   
            }
        }
    }

    &__reviews {
    	max-width: 1200px;
   		margin: 0 auto;
    	padding: 50px 70px 40px 70px;
    	@media only screen and (max-width: 770px) {
			padding: 30px 40px 40px 40px;
		}
    	&-col {
    		padding-bottom: 40px;
    		@media only screen and (min-width: 771px) {
	    		padding-left: 30px;
    			padding-right: 30px;
			}
    	}
        @media (max-width: 830px){
            .row {
                margin-right: 0px;  
                margin-left: 0px;   
            }
        }
    }
}