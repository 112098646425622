.react-viewer-canvas > img.drag  {
    cursor: default !important;
}

.gallerypage {
	background-size: 10px 10px;
    background-image: linear-gradient(to right, #f3f3f3 1px, transparent 1px), linear-gradient(to bottom, #f3f3f3 1px, transparent 1px);

	&__banner {
		background-image: linear-gradient(rgba(0, 0, 0, .65) 0%, rgba(0, 0, 0, 0) 40%), url('../../global_images/grassbanner.jpg');
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 300px;
		width: 100%;
		padding-top: 150px;
		@media only screen and (max-width: 770px) {
			padding-top: 0px;
		}


		&-logo {	
			@media only screen and (min-width: 771px) {
				display: none;
			}
			text-align: center;
			margin: 0 auto;
			img {
				margin-top: 80px;
				width: 140px;
                cursor: pointer;
			}
		}
	}

	&__buttons {
    	padding-top: 30px;
    	text-align: center;
    	@media only screen and (max-width: 770px) {
    		padding-top: 20px;
		}
    	.commonbutton {
    		display: inline-block;
    		width: 300px;
    		margin-left: 12px;
    		margin-right: 12px;
    		margin-bottom: 25px;
    		@media only screen and (max-width: 400px) {
    			width: 250px;
    		}
    	}
    }

    &__body {
    	max-width: 1300px;
    	padding-top: 80px;
    	margin: 0 auto;
    	padding-left: 40px;
    	padding-right: 40px;
    	@media only screen and (max-width: 770px) {
    		padding-top: 50px;
    	}
    }

    &__gallerysection {
    	&-icon {
    		text-align: center;
    		img {
    			width: 35px;
    			display: inline-block;
    			vertical-align: sub;
    			margin-right: 13px;
    		}
    		h1 {
    			display: inline-block;
	    		font-weight: 900;
	    		color: #4e4e4e;
	    		text-align: center;
	    		padding-bottom: 10px;
	    		margin-top: 0;
	    	}
	    	div {
	    		display: inline-block;
	    	}
    	}

    	&-subheader {
    		padding-bottom: 25px;
		    font-size: 18px;
		    font-weight: 400;
		    color: #4e4e4e;
		    letter-spacing: 0.2px;
    		text-align: center;
    		line-height: 23px;
    	}
    }

    &__projects {
    	h2 {
    		text-align: center;
		    font-weight: 700;
		    color: #868686;
		    letter-spacing: 2px;
		    font-size: 26px;
    	}
    	.threewaytoggle {
    		padding-top: 5px;
    		text-align: center;
    		padding-bottom: 25px;
    	}
    	&-pic {
    		// box-shadow: 0px 9px 26px -12px rgba(0,0,0,0.54);
    		&-common {
    			width: 100%;
    			box-shadow: 0px 9px 26px -12px rgba(0,0,0,0.54);
    			cursor: pointer;
    			filter: brightness(1);
    			transition: filter 0.2s;
    			height: 212px;
    			background-size: cover;
    			background-position: center;
    			@media only screen and (max-width: 767px) {
	    			margin-bottom: 20px;
	    		}
    		}
    		&-common:hover {
    			filter: brightness(0.8);
    		}

    		&-blythe {
    			&-before {
    				&-1 {
    					background-image: url('./images/BlytheJob/Before/blythe-before-1-preview.jpg');
    				}
    				&-2 {
    					background-image: url('./images/BlytheJob/Before/blythe-before-2-preview.jpg');
    				}
    				&-3 {
    					background-image: url('./images/BlytheJob/Before/blythe-before-3-preview.jpg');
    				}
    				&-4 {
    					background-image: url('./images/BlytheJob/Before/blythe-before-4-preview.jpg');
    				}
    			}
    			&-during {
    				&-1 {
    					background-image: url('./images/BlytheJob/During/blythe-during-1-preview.jpg');
    				}
    				&-2 {
    					background-image: url('./images/BlytheJob/During/blythe-during-2-preview.jpg');
    				}
    				&-3 {
    					background-image: url('./images/BlytheJob/During/blythe-during-3-preview.jpg');
    				}
    				&-4 {
    					background-image: url('./images/BlytheJob/During/blythe-during-4-preview.jpg');
    				}
    			}
    			&-after {
    				&-1 {
    					background-image: url('./images/BlytheJob/After/blythe-after-1-preview.jpg');
    				}
    				&-2 {
    					background-image: url('./images/BlytheJob/After/blythe-after-2-preview.jpg');
    				}
    				&-3 {
    					background-image: url('./images/BlytheJob/After/blythe-after-3-preview.jpg');
    				}
    				&-4 {
    					background-image: url('./images/BlytheJob/After/blythe-after-4-preview.jpg');
    				}
    			}
    		}

    		&-christiansen {
    			&-before {
    				&-1 {
    					background-image: url('./images/Christiansen/Before/christiansen-before-1-preview.jpg');
    				}
    				&-2 {
    					background-image: url('./images/Christiansen/Before/christiansen-before-2-preview.jpg');
    				}
    				&-3 {
    					background-image: url('./images/Christiansen/Before/christiansen-before-3-preview.jpg');
    				}
    				&-4 {
    					background-image: url('./images/Christiansen/Before/christiansen-before-4-preview.jpg');
    				}
    			}
    			&-during {
    				&-1 {
    					background-image: url('./images/Christiansen/During/christiansen-during-1-preview.jpg');
    				}
    				&-2 {
    					background-image: url('./images/Christiansen/During/christiansen-during-2-preview.jpg');
    				}
    				&-3 {
    					background-image: url('./images/Christiansen/During/christiansen-during-3-preview.jpg');
    				}
    				&-4 {
    					background-image: url('./images/Christiansen/During/christiansen-during-4-preview.jpg');
    				}
    			}
    			&-after {
    				&-1 {
    					background-image: url('./images/Christiansen/After/christiansen-after-1-preview.jpg');
    				}
    				&-2 {
    					background-image: url('./images/Christiansen/After/christiansen-after-2-preview.jpg');
    				}
    				&-3 {
    					background-image: url('./images/Christiansen/After/christiansen-after-3-preview.jpg');
    				}
    				&-4 {
    					background-image: url('./images/Christiansen/After/christiansen-after-4-preview.jpg');
    				}
    			}
    		}

    		&-cleanups {
    			&-before {
    				&-1 {
    					background-image: url('./images/Cleanups/Before/cleanups-before-1-preview.jpg');
    				}
    				&-2 {
    					background-image: url('./images/Cleanups/Before/cleanups-before-2-preview.jpg');
    				}
    			}
    			&-after {
    				&-1 {
    					background-image: url('./images/Cleanups/After/cleanups-after-1-preview.jpg');
    				}
    				&-2 {
    					background-image: url('./images/Cleanups/After/cleanups-after-2-preview.jpg');
    				}
    				&-3 {
    					background-image: url('./images/Cleanups/After/cleanups-after-3-preview.jpg');
    				}
    				&-4 {
    					background-image: url('./images/Cleanups/After/cleanups-after-4-preview.jpg');
    				}
    			}
    		}

            &-frontyard {
                &-during {
                    &-1 {
                        background-image: url('./images/FrontYardRedesign/During/frontyardredesign-during-1-preview.jpeg');
                    }
                    &-2 {
                        background-image: url('./images/FrontYardRedesign/During/frontyardredesign-during-2-preview.jpg');
                    }
                    &-3 {
                        background-image: url('./images/FrontYardRedesign/During/frontyardredesign-during-3-preview.jpg');
                    }
                    &-4 {
                        background-image: url('./images/FrontYardRedesign/During/frontyardredesign-during-4-preview.jpg');
                    }
                }
                &-after {
                    &-1 {
                        background-image: url('./images/FrontYardRedesign/After/frontyardredesign-after-1-preview.jpeg');
                    }
                    &-2 {
                        background-image: url('./images/FrontYardRedesign/After/frontyardredesign-after-2-preview.jpg');
                    }
                    &-3 {
                        background-image: url('./images/FrontYardRedesign/After/frontyardredesign-after-3-preview.jpg');
                    }
                    &-4 {
                        background-image: url('./images/FrontYardRedesign/After/frontyardredesign-after-4-preview.jpg');
                    }
                }
            }

            &-drainrock {
                &-during {
                    &-1 {
                        background-image: url('./images/DrainRockInstallation/During/drainrockinstallation-during-1-preview.jpeg');
                    }
                    &-2 {
                        background-image: url('./images/DrainRockInstallation/During/drainrockinstallation-during-2-preview.jpeg');
                    }
                    &-3 {
                        background-image: url('./images/DrainRockInstallation/During/drainrockinstallation-during-3-preview.jpg');
                    }
                    &-4 {
                        background-image: url('./images/DrainRockInstallation/During/drainrockinstallation-during-4-preview.jpg');
                    }
                }
                &-after {
                    &-1 {
                        background-image: url('./images/DrainRockInstallation/After/drainrockinstallation-after-1-preview.jpg');
                    }
                    &-2 {
                        background-image: url('./images/DrainRockInstallation/After/drainrockinstallation-after-2-preview.jpg');
                    }
                }
            }

            &-leak {
                &-during {
                    &-1 {
                        background-image: url('./images/MassiveLeakFix/During/massiveleakfix-during-1-preview.jpg');
                    }
                    &-2 {
                        background-image: url('./images/MassiveLeakFix/During/massiveleakfix-during-2-preview.jpg');
                    }
                    &-3 {
                        background-image: url('./images/MassiveLeakFix/During/massiveleakfix-during-3-preview.jpg');
                    }
                    &-4 {
                        background-image: url('./images/MassiveLeakFix/During/massiveleakfix-during-4-preview.jpg');
                    }
                }
                &-after {
                    &-1 {
                        background-image: url('./images/MassiveLeakFix/After/massiveleakfix-after-1-preview.jpeg');
                    }
                }
            }

            &-grass {
                &-before {
                    &-1 {
                        background-image: url('./images/GrassRemovalRock/Before/grassremovalrock-before-1-preview.jpeg');
                    }
                }
                &-during {
                    &-1 {
                        background-image: url('./images/GrassRemovalRock/During/grassremovalrock-during-1-preview.jpeg');
                    }
                    &-2 {
                        background-image: url('./images/GrassRemovalRock/During/grassremovalrock-during-2-preview.jpeg');
                    }
                    &-3 {
                        background-image: url('./images/GrassRemovalRock/During/grassremovalrock-during-3-preview.jpeg');
                    }
                    &-4 {
                        background-image: url('./images/GrassRemovalRock/During/grassremovalrock-during-4-preview.jpeg');
                    }
                }
                &-after {
                    &-1 {
                        background-image: url('./images/GrassRemovalRock/After/grassremovalrock-after-1-preview.jpeg');
                    }
                    &-2 {
                        background-image: url('./images/GrassRemovalRock/After/grassremovalrock-after-2-preview.jpeg');
                    }
                }
            }

            &-concrete {
                &-1 {
                    background-image: url('./images/ConcreteWork/concrete-work-1-preview.jpg');
                }
                &-2 {
                    background-image: url('./images/ConcreteWork/concrete-work-2-preview.jpg');
                }
            }



    		img {
    			width: 100%;
    			box-shadow: 0px 9px 26px -12px rgba(0,0,0,0.54);
    			cursor: pointer;
    			filter: brightness(1);
    			transition: filter 0.2s;
    			@media only screen and (max-width: 767px) {
	    			margin-bottom: 20px;
	    		}
    		}
    		img:hover {
    			filter: brightness(0.8);
    		}
    	}
    	&-common {
    		padding-bottom: 20px;
    	}
    }

    &__leak, &__drain, &__frontyard {
        .threewaytoggle__during {
            border-left: 1px solid #d4d4d4;
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
        }
    }

    &__concrete {
        h2 {
            padding-bottom: 20px;
        }
    }

    .seventhsection {
    	margin-top: 50px;
    	background-color: #404041;
	    background-image: linear-gradient(to right, #48484a 1px, transparent 1px), linear-gradient(to bottom, #48484a 1px, transparent 1px);
    }

}