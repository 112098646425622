.specialoffer {

	&__body {
		&-offers {
			border-bottom: 1px solid #ccc;
			padding-bottom: 20px;
			@media only screen and (max-width: 768px) {
				border-bottom: 1px solid #ccc;
				padding-bottom: 30px;
			}
			h1 {
				font-weight: 800;
				font-size: 35px;
				color: #7F7F7F;
				margin-bottom: 20px;
				@media only screen and (max-width: 768px) {
					text-align: center;
					font-size: 25px;
				}

			}
			&-content {
				font-size: 16px;
				// font-weight: 300;
				line-height: 26px;
				padding-bottom: 20px;
			}
			&-discount {
		    	border: 2px dashed gray;
		    	transition: 300ms background;
		    	background: white;
		    	padding: 10px;
		    	padding-top: 20px;
		    	padding-bottom: 20px;
		    	margin-bottom: 20px;
		    	&-header {
		    		text-align: center;
		    		font-size: 20px;
		    		color: #afb36d;
		    		text-transform: uppercase;
		    	}
		    	&-content {
		    		padding-top: 10px;
		    		text-align: center;
		    		font-size: 15px;
		    		padding-left: 15px;
		    		padding-right: 15px;
		    		// font-weight: 100;
		    	}
		    	&-redeem {
		    		margin: 0 auto;
		    		text-align: center;
		    		margin-top: 20px;
		    		&-btn {
		    			color: white;
		    			cursor: pointer;
		    			height: 40px;
					    padding: 10px 25px 15px 25px;
					    margin: 0 auto;
					    text-align: center;
					    background: #afb36d;
					    width: 130px;
		    		}
		    		&-btn:hover {
		    			filter: brightness(1.15);
		    		}
		    		&-arrow-btn {
		    			cursor: pointer;
		    			color: white;
		    			height: 40px;
					    padding: 10px 25px 15px 12px;
					    margin: 0 auto;
					    text-align: center;
					    width: 30px;
					    background: #afb36d;
					    display: inline-block;
		    		}
		    		&-arrow-btn:hover {
		    			filter: brightness(1.15);
		    		}
		    		input:focus {
					    outline-width: 2px;
					}
		    		&-input {
		    			display: inline-block;
					    vertical-align: top;
					    border: 1px solid #e0e0e0;
					    padding: 9px;
					    margin-bottom: 2px;
					    border-radius: 2px 0 0 2px;
					    border-right: none;
				    	@media (max-width:335px){
				    		border: 1px solid #e0e0e0;
							width: 130px;
						}
		    		}
		    		&-sent {
		    			font-weight: 100;
					    color: green;
					    font-size: 17px;
					    padding: 10px;
		    		}
		    		&-error {
		    			font-weight: 100;
		    			color: red;
		    		}
		    	}
		    }
		    &-discount:hover {
		    	background: #efefef;

		    }
		}
	}
}