.aboutpage {
	background-size: 10px 10px;
    background-image: linear-gradient(to right, #f3f3f3 1px, transparent 1px), linear-gradient(to bottom, #f3f3f3 1px, transparent 1px);

	&__banner {
		background-image: linear-gradient(rgba(0, 0, 0, .65) 0%, rgba(0, 0, 0, 0) 40%), url('../../global_images/grassbanner.jpg');
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 300px;
		width: 100%;
		padding-top: 150px;
		@media only screen and (max-width: 770px) {
			padding-top: 0px;
		}


		&-logo {	
			@media only screen and (min-width: 771px) {
				display: none;
			}
			text-align: center;
			margin: 0 auto;
			img {
				margin-top: 80px;
				width: 140px;
                cursor: pointer;
			}
		}
	}

	&__buttons {
    	padding-top: 30px;
    	text-align: center;
    	@media only screen and (max-width: 770px) {
    		padding-top: 20px;
		}
    	.commonbutton {
    		display: inline-block;
    		width: 300px;
    		margin-left: 12px;
    		margin-right: 12px;
    		margin-bottom: 25px;
    		@media only screen and (max-width: 400px) {
    			width: 250px;
    		}
    	}
    }

    &__body {
    	max-width: 1200px;
    	padding-top: 80px;
    	margin: 0 auto;
    	padding-left: 40px;
    	padding-right: 40px;
    	@media only screen and (max-width: 770px) {
    		padding-top: 50px;
    	}
    }

    &__ourteam {
    	padding-bottom: 10px;
    	&-icon {
    		text-align: center;
    		img {
    			width: 35px;
    			display: inline-block;
    			vertical-align: sub;
    			margin-right: 13px;
    		}
    		h1 {
    			display: inline-block;
	    		font-weight: 900;
	    		color: #4e4e4e;
	    		text-align: center;
	    		padding-bottom: 10px;
	    		margin-top: 0;
	    	}
	    	div {
	    		display: inline-block;
	    	}
    	}

    	&-subheader {
    		padding-bottom: 35px;
		    font-size: 18px;
		    font-weight: 400;
		    color: #4e4e4e;
		    letter-spacing: 0.2px;
    		text-align: center;
    		line-height: 23px;
    	}

    	&-profile-div {
    		@media only screen and (min-width: 1150px) {
	    		padding: 10px;
	    		padding-bottom: 0;
    		}
    	}

    	&-profile {
    		position: relative;
    		width: 100%;
    		// box-shadow: -2px 0px 21px -10px rgba(0,0,0,0.54);
    		// border: 1px solid #ddd;
    		margin-bottom: 30px;

    		&-pic {
    			position: relative;
    			width: 100%;
    			text-align: center;
    			height: 200px;

    			img {
    				width: 200px;
    				border-radius: 50%;
    			}
    		}

    		&-text {
    			text-align: center;
    			padding: 30px 28px 30px 28px;
    		}

    		&-name {
    			h3 {
	    			padding: 0;
	    			margin: 0;
	    			font-weight: 600;
	    			color: #676767;
    			}
    		}

    		&-title {
    			padding-top: 5px;
    			font-weight: 600;
    			font-size: 15px;
    			letter-spacing: 1.3px;
    			padding-bottom: 15px;
    			color: #888888;
    		}

    		&-bio {
    			font-size: 16px;
    			line-height: 23px;
    			// font-weight: 300;
    			// color: black;
    		}
    	}
    }

   	&__whyus {	
   		padding-bottom: 60px;	
   		&-icon {
    		text-align: center;
    		img {
    			width: 30px;
    			display: inline-block;
    			vertical-align: sub;
    			margin-right: 13px;
    		}
    		h1 {
    			display: inline-block;
	    		font-weight: 900;
	    		color: #4e4e4e;
	    		text-align: center;
	    		padding-bottom: 10px;
	    		margin-top: 0px;
	    	}
    	}

    	h3 {
    		font-weight: 400;
		    color: #828282;
		    font-size: 21px;
		    text-align: center;
    	}

    	&-subheader-points {
		    font-size: 25px;
		    padding-bottom: 20px;
		    font-weight: 400;
		    color: #4e4e4e;
		    letter-spacing: 0.2px;
		    line-height: 35px;
		    max-width: 570px;
		    margin: 0 auto;
		    clear: both;
		    @media only screen and (max-width: 770px) {
	    		font-size: 22px;
			    line-height: 30px;
	    	}
    	}

    	.commonbutton {
    		margin: 0 auto;
    		width: 230px;
    		background-color: white;
   			border: 1px solid #cccccc;
    	}
    	.commonbutton:hover {
    		background-color: #d8d8d8;
    	}

    }

    &__story {
    	padding-bottom: 70px;
    	&-icon {
    		text-align: center;
    		img {
    			width: 34px;
    			display: inline-block;
    			vertical-align: sub;
    			margin-right: 13px;
    		}
    		h1 {
    			display: inline-block;
	    		font-weight: 900;
	    		color: #4e4e4e;
	    		text-align: center;
	    		padding-bottom: 10px;
	    		margin-top: 0px;
	    	}
    	}

	    &-pic {
	    	img {
				width: 220px;
	    	}
			float: left;
			margin-right: 15px;
			box-shadow: -2px 0px 21px -10px rgba(0,0,0,0.54);
			border: 4px solid #ffffff40;
			margin-bottom: 10px;
			width: 228px;
			@media only screen and (max-width: 500px) {
				float: none;
				margin: 0 auto;
				margin-bottom: 20px;
				width: fit-content;
			}
		}

		&-text {
			font-size: 17px;
		    font-weight: 400;
		    color: #4e4e4e;
		    letter-spacing: 0.3px;
		}

		clear: both;
    }

    &__reviews {
    	padding-bottom: 60px;
    	&-icon {
    		text-align: center;
    		img {
    			width: 34px;
    			display: inline-block;
    			vertical-align: sub;
    			margin-right: 13px;
    		}
    		h1 {
    			display: inline-block;
	    		font-weight: 900;
	    		color: #4e4e4e;
	    		text-align: center;
	    		padding-bottom: 10px;
	    		margin-top: 0px;
	    	}
    	}

		clear: both;
    	h1 {
    		font-weight: 900;
    		color: #4e4e4e;
    		padding-bottom: 30px;
    		text-align: center;
    	}

    	&-bubbles {
    		text-align: center;
            // a {
            //     color: black !important;
            // }
    	}

    	.review {
    		margin: 0;
    		display: inline-block;
    		margin-left: 25px;
    		margin-right: 25px;
    		margin-bottom: 40px;
    	}

    	.ratingparent {
    		display: inline-block;
    		margin-right: 30px;
    		margin-left: 30px;
    	}

    	.rating {
    		margin: 0;
    		text-align: left;
    		&__link {
                a {
    			     color: #5b9acc !important;
    			     border-bottom: 1px solid #5b9acc !important;
                }
    		}
    	}

    }

    &__faq {
    	padding-bottom: 60px;
    	clear: both;
    	&-icon {
    		text-align: center;
    		img {
    			width: 34px;
    			display: inline-block;
    			vertical-align: sub;
    			margin-right: 13px;
    		}
    		h1 {
    			display: inline-block;
	    		font-weight: 900;
	    		color: #4e4e4e;
	    		text-align: center;
	    		padding-bottom: 10px;
	    		margin-top: 0px;
	    	}
    	}

    	&-body {
    		padding-top: 20px;
    	}

    	&-bubble {
    		background: white;
		    padding: 18px;
		    font-size: 17px;
		    box-shadow: 0px 13px 24px -18px rgba(0,0,0,0.3);

		    &-question {
		    	font-weight: 600;
		    	padding-bottom: 10px;
		    }
		    margin-bottom: 20px;
    	}
    }

    &__calculator {
    	padding-bottom: 50px;
    	&-icon {
    		text-align: center;
    		img {
    			width: 34px;
    			display: inline-block;
    			vertical-align: sub;
    			margin-right: 13px;
    		}
    		h1 {
    			display: inline-block;
	    		font-weight: 900;
	    		color: #4e4e4e;
	    		text-align: center;
	    		padding-bottom: 10px;
	    		margin-top: 0px;
	    	}
    	}
    	h3 {
    		text-align: center;
    	}
    }


}