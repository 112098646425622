.rating {

  width: 120px;
  margin: 0 auto;

  &__circle {
    background: #F3F1F3;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto;

    &-rating {
      padding-top: 30px;
      font-size: 28px;
      letter-spacing: 1px;
      position: relative;

      sup {
        font-weight: 700;
        padding-right: 5px;
        position: relative;
        top: -15px;
      }

      &-slash {
        display: inline-block;
        transform: rotate(22deg);
        height: 58px;
        position: absolute;
        top: 22px;
      }

      sub {
        font-weight: 300;
        padding-left: 5px;
        position: relative;
        top: 10px;
      }     
    }
  }

  &__logo {
    position: relative;
    top: -40px;
    left: -20px;
    @media only screen and (max-width: 767px) {
       left: -10px;
    }
    img {
      width: 55px;
      border-radius: 50%;
    }
  }

  &__link {
    position: relative;
    top: -19px;
    text-align: center;
    color: white;
    font-size: 17px;
    letter-spacing: 0.3px;
    border-bottom: 1px solid white;
    font-weight: 700;
    cursor: pointer;
    width: 100px;
    a {
      text-decoration: none;
      color: white;
    }
    a:visited {
      color: white;
    }
    @media only screen and (max-width: 767px) {
       top: -25px;
    }
  }

  &__link:hover {
    opacity: 0.6;
  }

  &__link:active {
    opacity: 0.7;
  }


}

.yelprating {
  &__circle {

    &-rating {
      color: #dc0000;

      &-slash {
        border-left: 1px solid red;
      } 
    }
  }

  &__logo {
    img {
      padding: 9px;
      background: #dc0000;
    }
  }
}

.facebookrating {
  &__circle {

    &-rating {
      color: #587dbd;

      &-slash {
        border-left: 1px solid #587dbd;
      } 
    }
  }

  &__logo {
    img {
      background: white;
    }
  }
}

.googlerating {
  &__circle {

    &-rating {
      color: #dc0000;

      &-slash {
        border-left: 1px solid red;
      } 
    }
  }

  &__logo {
    img {
      padding: 3px;
      // background: white;
    }
  }
}