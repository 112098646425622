.firstsection {
	width: 100%;
	// background-size: cover;
	min-height: 730px;
	@media only screen and (max-width: 770px) {
		min-height: 650px;
	}
	// background-image: url('./images/3.jpg');

	&__background {
		min-height: 730px;
		position: absolute;
		z-index: -10;
		width: 100%;

        transition: opacity 1.5s ease-in-out;
        background-repeat: no-repeat;
        background-size: cover;
		background-color: gray;

        @media only screen and (max-width: 779px) {
			min-height: 800px;
		}

		@media only screen and (max-width: 770px) {
			min-height: 650px;
			background-position: center;
		}
		
		&-1 {
			background-image: url('./images/1.jpg');
		}
		&-2 {
			background-image: url('./images/2.jpg');

		}
		&-3 {
			background-image: url('./images/3.jpg');

		}
		&-4{
			background-image: url('./images/4.jpg');
		}
	}

	&__background-active {
		opacity: 1;
		z-index: -10;
	}
	&__background-inactive {
		opacity: 0;		
		z-index: -11;
	}

	&__buffer {
		width: 100%;
		@media only screen and (max-width: 770px) {
			height: 70px;
		}
		@media only screen and (min-width: 771px) {
			height: 270px;
		}
	}

	&__mobile-logo {
		width: 100%;
		margin-top: 30px;
		text-align: center;
		img {
			width: 200px;
		}
		@media only screen and (min-width: 771px) {
			display: none;
		}
	}

	&__header {
		width: 100%;
		text-align: center;
		h1 {
			color: white;
			@media only screen and (max-width: 770px) {
				font-size: 26px;
				line-height: 31px;
				font-weight: 700;
				padding-left: 45px;
				padding-right: 45px;
			}
			@media only screen and (min-width: 771px) {
				font-weight: 600;
				letter-spacing: 1px;
				font-size: 40px;
			}
		}
	}

	&__subheader {
		width: 100%;
		text-align: center;
		h5 {
			color: white;
			@media only screen and (max-width: 770px) {
				font-size: 15px;
				font-style: italic;
				padding-left: 10px;
				padding-right: 10px;
			    font-weight: 700;
				padding-top: 10px;
			}
			@media only screen and (min-width: 771px) {
				padding-top: 11px;
			    font-weight: 400;
			    letter-spacing: 0.4px;
			    font-size: 17px;
			    color: white;
			}
		}
	}

	&__CTA {
		padding-top: 60px;
		margin: 0 auto;
		text-align: center;
		@media only screen and (max-width: 770px) {
			padding-top: 30px;
		}
		.commonbutton {
			width: 350px;
			margin: 10px 20px 10px 20px;
			letter-spacing: 1px;
			@media only screen and (max-width: 770px) {
				width: 220px;
				font-size: 12px;
			}
		}
		.greenbutton {
			@media only screen and (max-width: 770px) {
				border: 1px solid white;
			}
		}
		&-call {
			display: inline-block;
		}
		&-schedule {
			display: inline-block;
		}
	}

	&__bottom {
		padding-top: 50px;
		height: 200px;
		@media only screen and (max-width: 900px) {
			padding-top: 10px;
		}

		@media only screen and (max-width: 770px) {
			display: none;
		}

		&-facebook {
			width: 33.3333%;
			display: inline-block;
			height: 150px;
			position: relative;
			a {
				color: white;
				text-decoration: none;
			}
			a:visited {
				color: white;
			}
			&-icon {
				position: absolute;
				top: 80px;
				left: 40px;
				@media only screen and (max-width: 900px) {
					top: 140px;
				}
			}
			&-review {
				width: 300px;
				background-color: #0000007a;
				color: white;
				padding: 15px 25px 15px 25px;
				border-radius: 15px;
				margin-left: 70px;
				@media only screen and (max-width: 900px) {
					width: 200px;
				}
				.glyphicon {
					color: #51b5f9;
				}
				&-stars {
					padding-top: 3px;
				}
			}
		}

		&-arrow {
			width: 33.3333%;
			display: inline-block;
			text-align: center;
			span {
				cursor: pointer;
				font-size: 45px;
    			color: #ffffffc7;
				top: 40px;
				position: relative;
				@media only screen and (max-width: 900px) {
					top: 20px;
				}
			}
		}

		&-portfolio {
			width: 33.3333%;
			display: inline-block;
			&-text {
				cursor: pointer;
				color: white;
			    right: 35px;
			    position: relative;
			    top: 38px;
			    border-bottom: 1px solid white;
			    float: right;
			    @media only screen and (max-width: 900px) {
					top: 20px;
				}
			}
			&-text:hover {
				opacity: 0.8;
			}
			&-text:active {
				opacity: 1;
			}
			clear: both;
		}
	}

	&__bottom-mobile {
		@media only screen and (min-width: 771px) {
			display: none;
		}
		text-align: center;
		margin: 0 auto;
	}

}