.secondsection {
    width: 100%;
    background-size: 10px 10px;
    background-image: linear-gradient(to right, #f3f3f3 1px, transparent 1px), linear-gradient(to bottom, #f3f3f3 1px, transparent 1px);
    padding-bottom: 30px;

    &__subheader {
    	color: #595959;
	    font-size: 18px;
	    text-align: center;
	    padding-top: 60px;
	    letter-spacing: 3px;

	    @media only screen and (max-width: 779px) {
			font-size: 13px;
		    padding-top: 60px;
		}
	}

	&__header {
		h1 {
			font-weight: 900;
	    	color: #6f6f6f;
	    	text-align: center;
			font-size: 30px;
			margin-top: 17px;

	    	@media only screen and (max-width: 900px) {
				font-size: 25px;
			}

			 @media only screen and (max-width: 622px) {
				font-size: 23px;
				padding-left: 40px;
				padding-right: 40px;
			}
		}
	}

	&__dropdown {
		padding-top: 20px;
		width: 100%;
		margin: 0 auto;
		text-align: center;

		@media only screen and (max-width: 500px) {
			text-align: center;
		}

		@media only screen and (max-width: 369px) {
			width: 290px;
		}

		&-select {
			display: inline-block;
			width: 350px;
			@media only screen and (max-width: 500px) {
				width: 250px;
			}
			@media only screen and (max-width: 369px) {
				width: 200px;
			}
		}

		&-select__control {
			padding-left: 5px;
		    height: 46px;
			border-top-left-radius: 50px !important;
    		border-bottom-left-radius: 50px !important;
    		border-right: 0 !important;
    		box-shadow: none !important;
    		font-size: 16px;
    		border-width: 2px !important;
    		border-color: hsl(143, 46%, 33%) !important;
		}

		&-select__value-container {
			flex-direction: column;
		}

		&-button {
			display: inline-block;
		    vertical-align: bottom;
			.commonbutton {
				border-radius: 0 50px 50px 0;
    			padding: 12px 19px 12px 15px;
    			border: 1px solid #2e7b4b;
			}
			.commonbutton:active {
			    transform: translateY(0px) !important;
			}
		}
	}

	&__contact {
		animation: slide-up 1s ease;
		position: absolute;
	    box-shadow: -2px 8px 21px -1px rgba(0,0,0,0.54);
	    width: 80%;
	    background: #e8e8e8;
	    padding: 10px 30px 30px 30px;
	    margin: 0 auto;
	    text-align: center;
	    left: 0;
	    right: 0;
	    margin-left: auto;
	    margin-right: auto;
	    z-index: 2;
	    @media only screen and (max-width: 770px) {
			padding-top: 25px;
		}
	    &-close {
	    	position: absolute;
		    right: 21px;
		    font-size: 24px;
		    color: #8c8c8c;
		    @media only screen and (max-width: 770px) {
				font-size: 19px;
				top: 14px;
			}
			span {
				cursor: pointer;
			}
			span:hover {
				color: #505050;
			}
	    }
	    h3 {
	    	padding-bottom: 10px;
	    	@media only screen and (max-width: 770px) {
				font-size: 18px;
			}
	    }
	    .greenbutton {
	    	width: 300px;
	    	margin: 0 auto;
		    background-color: #afb36d;
		    @media only screen and (max-width: 770px) {
		    	font-size: 12px;
	    		width: 200px;
		    }
	    }
	    .whitebutton {
	    	margin: 0 auto;
	    	border: 1px solid #ccc;
	    	width: 98%;

	    }
	    &-break {
	    	margin-top: 25px;
		    width: 100%;
		    height: 1px;
		    background: #c3c3c3;
	    }
	}

	&__space {
		padding-top: 40px;
		@media only screen and (max-width: 770px) {
			padding-top: 30px;
		}
	}

	&__services {
		max-width: 1180px;
    	margin: 0 auto;
	    @media only screen and (max-width: 1199px) {
			max-width: 800px;
		}
		@media only screen and (max-width: 500px) {
			max-width: 300px;
		}
		&-bubble {
			width: 310px;
    		height: 180px;
    		background: black;
    		margin: 0 auto;
    		margin-bottom: 50px;
    		border-radius: 150px;
    		position: relative;
    		@media only screen and (max-width: 700px) {
	    		margin-bottom: 30px;
	    		width: 300px;
			}
			&-wrapper {
				width: 100%;
				height: 100%;
			}
			&-wrapper:hover {
				.secondsection__services-bubble-cover {
					animation: go-up-and-fade-out 0.5s;
					animation-fill-mode: both;
				}
				.secondsection__services-bubble-info {
					animation: go-up-and-fade-in 0.5s;
					animation-fill-mode: both;
				}
			}
			&-cover {
				 -webkit-transform:translateY(0);
				text-align: center;
				color: white;
				position: absolute;
				top: 45px;
				width: 100%;
				animation: come-down-and-fade-in 0.5s;
				animation-fill-mode: both;

				img{  
					width: 40px;
					filter: contrast(0) brightness(10);
					padding-bottom: 10px;
				}
				&-title {
					font-size: 17px;
					line-height: 22px;
				}

			}
			
			&-info {
				text-align: center;
				color: white;
				position: absolute;
				top: 25px;
				width: 100%;
				animation: go-down-and-fade-out 0.5s;
				animation-fill-mode: both;
				.commonbutton {
					margin: 0 auto;
					padding: 10px 20px 10px 20px;
					width: 160px;
				}
				&-text{
					padding-bottom: 20px;
					width: 90%;
				    margin: 0 auto;
				}
			}	

			&-maintenance {
				background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url('../../../global_images/services/mowing-lawn.jpg');
				background-size: cover;
			}
			&-maintenance:hover {
				@media only screen and (min-width: 770px) {
					background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url('../../../global_images/services/mowing-lawn.jpg');
				}
			}

			&-fertilization {
				background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url('../../../global_images/services/fertilization.jpg');
				background-size: cover;
			}
			&-fertilization:hover {
				@media only screen and (min-width: 770px) {
					background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url('../../../global_images/services/fertilization.jpg');
				}
			}

			&-sprinkler {
				background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url('../../../global_images/services/sprinkler.jpg');
				background-size: cover;
			}
			&-sprinkler:hover {
				@media only screen and (min-width: 770px) {
					background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url('../../../global_images/services/sprinkler.jpg');
				}
			}

			&-palmtree {
				background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url('../../../global_images/services/palmtree.jpg');
				background-size: cover;
				background-position: bottom;
			}
			&-palmtree:hover {
				@media only screen and (min-width: 770px) {
					background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url('../../../global_images/services/palmtree.jpg');
				}
			}

			&-landscape {
				background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url('../../../global_images/services/landscape.jpg');
				background-size: cover;
				background-position: bottom;
			}
			&-landscape:hover {
				@media only screen and (min-width: 770px) {
					background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url('../../../global_images/services/landscape.jpg');
				}
			}

			&-concrete {
				background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url('../../../global_images/services/concrete.jpg');
				background-size: cover;
				background-position: center;
			}
			&-concrete:hover {
				@media only screen and (min-width: 770px) {
					background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url('../../../global_images/services/concrete.jpg');
				}
			}
		}

		@media (max-width: 830px) {
			.row {
				margin-right: 0;	
				// margin-left: 0;
			}
		}
	}

}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes go-up-and-fade-out {
    0% {
        opacity: 1;
        transform: translateY(0);
        z-index: 0;
    }
    99% {
        opacity: 0;
        transform: translateY(-50px);
        z-index: 0;
    }
    100% {
        opacity: 0;
        transform: translateY(-50px);
        z-index: -9;
    }
}

@keyframes come-down-and-fade-in {
    0% {
        z-index: -9;
        opacity: 0;
    	transform: translateY(-50px);
    }
    1% {
    	z-index: 0;
    	opacity: 0;
    	transform: translateY(-50px);

    }
    100% {
    	z-index: 0;
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes go-up-and-fade-in {
    0% {
        opacity: 0;
        transform: translateY(15px);
    	z-index: -9;
    }
    1% {
        opacity: 0;
        transform: translateY(15px);
        z-index: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
        z-index: 1;
    }
}

@keyframes go-down-and-fade-out {
    0% {
        opacity: 1;
        transform: translateY(0);
        z-index: 0;
    }
    99% {
        opacity: 0;
        transform: translateY(15px);
        z-index: 0;
    }
    100% {
    	opacity: 0;
        transform: translateY(15px);
        z-index: -9;
    }
}