.firsthalfsection {
	background-color: #ececec;//  #f4f4f4;	
    height: 215px;
    @media only screen and (max-width: 770px) {
		height: auto;
	}

    video {
    	@media only screen and (max-width: 770px) {
			display: none;
		}
    }

    &__header {
    	display: none;
    	@media only screen and (max-width: 767px) {
			display: block;
		}
    	h1 {
			padding: 0;
			font-size: 23px;
			font-weight: 700;
			color: #6f6f6f;
			text-align: center;
			padding-top: 50px;
    	 	margin: 0;
    	}
	}	

	&__wrapper {
		position: relative;
		top: -100px;
		max-width: 1400px;
		margin: 0 auto;
		@media only screen and (max-width: 1455px) {
			max-width: 1300px;
		}
		@media only screen and (max-width: 1330px) {
			max-width: 1200px;
		}
		@media only screen and (max-width: 770px) {
			max-width: 100%;
			top: 0;
		}
		@media only screen and (max-width: 770px) {
			padding: 50px 50px 20px 50px;
		}
	}

	&-col {
		&-1 {
			padding-right: 25px; 
			padding-left: 25px;
			@media only screen and (max-width: 1330px) {
				padding-right: 0;
				padding-left: 50px;
			}
			@media only screen and (max-width: 770px) {
				padding: 0 0 80px 0;
			}
		}
		&-2 {
			padding-right: 25px; 
			padding-left: 25px;
			@media only screen and (max-width: 770px) {
				padding: 0 0 80px 0;
			}
		}
		&-3 {
			padding-left: 25px;
			padding-right: 25px; 
			@media only screen and (max-width: 1330px) {
				padding-right: 50px;
				padding-left: 0px;
			}
			@media only screen and (max-width: 770px) {
				padding: 0 0 80px 0;
			}

		}
	}

	&__box {
		&-outer {
			position: relative;
			width: 100%;
			height: 250px;
			box-shadow: 0px 28px 49px -20px rgba(0,0,0,0.75);
			transform: translate3d(0,0,0);
			animation: fadein 1s ease-in-out;
			&-1 {
				top: 20px;
			}
			&-2 {
				top: 20px;
			}
			&-3 {
				top: 20px;
			}
		}
		&-inner {
			overflow: hidden;
			width: 100%;
			height: 100%;
			background-size: cover;
			&-1 {
				background-image: url('./images/renderStill.jpg');
				@media only screen and (min-width: 770px) {
					background-image: url('./images/renderStillVid.jpg');
				}
			}
			&-2 {
				background-image: url('./images/realStill.jpg');
				@media only screen and (min-width: 770px) {
					background-image: url('./images/realStillVid.jpg');
				}
			}
			&-3 {
				background-image: url('./images/maintain.jpeg');
			}
		}

		&-circle {
		    position: absolute;
		    text-align: center;
		    width: 100px;
		    border-radius: 50%;
		    height: 100px;
		    top: -50px;
		    z-index: 10;
		    border: 10px solid #79b979;
		    background-color: white;
		    color: #333333;
		    left: 0;
		    right: 0;
		    margin-left: auto;
		    margin-right: auto;
		    h1 {
				font-weight: 700;
		    }
		}

		&-overlay {
			background-color: #6b6b6b5e;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			transition: background-color 1s;
		    border: 2px solid white;
				
			&-header {
				position: relative;
				width: 100%;
    			height: 100%;
				h1 {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: white;
					margin: 0;
					padding: 0;
					font-weight: 700;
					transition: opacity 300ms;
					text-align: center;
					letter-spacing: 3px;
    				font-size: 28px;
					// @media only screen and (max-width: 1272px) {
					// 	font-size: 30px;
					// }
				}
				h5 {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: white;
					text-align: center;
					width: 100%;
					padding-left: 40px;
					padding-right: 40px;
					font-size: 18px;
					opacity: 0;
					transition: opacity 300ms;
					letter-spacing: 0.3px;
				    line-height: 19px;
				    // font-weight: 700;
				}
				a {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					text-align: center;
				    padding-bottom: 8px;
				    color: white;
				    text-decoration: underline;
				    opacity: 0;
				    transition: opacity 300ms;
				}
			}
		}


		&-overlay:hover {
			background-color: #00000040;
			a {
				opacity: 1;
			}
			h1 {
				opacity: 0;
			}
			h5 {
				opacity: 1;
			}
		}

	
	}
}

@keyframes fadein {
    from { 
    	opacity: 0;
    	top: -20px;
     }
    to   { 
    	opacity: 1; 
    	top: 20px;

    }
}
