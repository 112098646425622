.fourthsection {
	background-color: #404041;
    width: 100%;
    background-size: 10px 10px;
    background-image: linear-gradient(to right, #48484a 1px, transparent 1px), linear-gradient(to bottom, #48484a 1px, transparent 1px);
    padding-top: 60px;
    padding-bottom: 60px;

    &__handshake {
    	text-align: center;
    	img {
    		width: 55px;
    		filter: invert(1);
    	}
    }

    &__header {
    	text-align: center;
    	h1 {
    		color: #F2F2F2;
		    margin-top: 10px;
		    font-weight: 600;
		    font-size: 33px;
		    @media only screen and (max-width: 770px) {
				font-size: 28px;
			}
    	}
    }

    &__subheader {
    	color: white;
	    text-align: center;
	    margin: 0 auto;
	    font-size: 20px;
	    max-width: 690px;
	    padding-top: 10px;
	    padding-left: 30px;
	    padding-right: 30px;
	    font-weight: 300;
	    line-height: 25px;
    }

    &__buttons {
    	padding-top: 30px;
    	text-align: center;
    	.commonbutton {
    		display: inline-block;
    		padding-left: 100px;
    		padding-right: 100px;
    		margin-left: 12px;
    		margin-right: 12px;
    		margin-bottom: 25px;
    		@media only screen and (max-width: 770px) {
				padding-left: 80px;
    			padding-right: 80px;
			}
    	}
    }
}