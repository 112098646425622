.servicespage {
	// min-height: 800px;
	background-size: 10px 10px;
    background-image: linear-gradient(to right, #f3f3f3 1px, transparent 1px), linear-gradient(to bottom, #f3f3f3 1px, transparent 1px);

	&__banner {
		background-image: linear-gradient(rgba(0, 0, 0, .65) 0%, rgba(0, 0, 0, 0) 40%), url('../../global_images/grassbanner.jpg');
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 300px;
		width: 100%;
		padding-top: 150px;
		@media only screen and (max-width: 770px) {
			padding-top: 0px;
		}

		&-logo {	
			@media only screen and (min-width: 771px) {
				display: none;
			}
			text-align: center;
			margin: 0 auto;
			img {
				margin-top: 80px;
				width: 140px;
                cursor: pointer;
			}
		}
	}

	&__buttons {
    	padding-top: 30px;
    	text-align: center;
    	@media only screen and (max-width: 770px) {
    		padding-top: 20px;
		}
    	.commonbutton {
    		display: inline-block;
    		width: 300px;
    		margin-left: 12px;
    		margin-right: 12px;
    		margin-bottom: 25px;
    		@media only screen and (max-width: 400px) {
    			width: 250px;
    		}
    	}
    }

    &__body {
    	max-width: 1300px;
    	padding-top: 80px;
    	margin: 0 auto;
    	padding-left: 40px;
    	padding-right: 40px;
    	@media only screen and (max-width: 770px) {
    		padding-top: 50px;
    	}
    }

    &__filter {
    	text-align: center;
    	padding-bottom: 50px;
    	&-icon {
    		display: inline-block;
    		img {
    			width: 30px;
    			// filter: contrast(0);
    		}
    		padding-right: 15px;
    	}
    	&-box {
    		display: inline-block;
    	}
    }

    &__dropdown {
    	&-select {
			display: inline-block;
			width: 350px;
			@media only screen and (max-width: 500px) {
				width: 250px;
			}
			@media only screen and (max-width: 369px) {
				width: 200px;
			}
		}

		&-select__control {
			padding-left: 5px;
		    height: 46px;
			border-radius: 50px !important;
    		box-shadow: none !important;
    		font-size: 16px;
    		border-width: 2px !important;
    		border-color: hsl(143, 46%, 33%) !important;
		}

		&-select__value-container {
			flex-direction: column;
		}
    }

    
    &__landscape-maintenance {
    	padding-bottom: 30px;
    	&-icon {
    		text-align: center;
    		padding-bottom: 30px;
    		img {
    			width: 30px;
    			display: inline-block;
    			vertical-align: sub;
    			margin-right: 13px;

    		}
    		&-shears {
    			img {
    				vertical-align: baseline;
    				width: 34px;
    				margin-right: 10px;
    			}
    		}
    		&-bush {
    			img {
    				width: 34px;
    				margin-right: 10px;
    				top: 4px;
    				position: relative;
    			}
    		}
    		h1 {
    			display: inline-block;
	    		font-weight: 900;
	    		color: #4e4e4e;
	    		text-align: center;
	    		padding-bottom: 10px;
	    		margin-top: 0px;
	    		@media only screen and (max-width: 500px) {
		    		font-size: 28px;
		    		display: block;
		    		padding-top: 5px;
	    			margin-bottom: 0px;
	    			padding-bottom: 0;
		    	}
	    	}
    	}



    	&-body {
    		text-align: center;
    	}

    	&-service {
    		box-shadow: 0px 13px 24px -18px rgba(0,0,0,0.54);
    		box-shadow: 0px 9px 26px -12px rgba(0,0,0,0.54);
    		margin-bottom: 30px;
    		&-pic {
    			height: 205px;
    			width: 100%;
    			background-size: cover;
    			background-position: bottom;
    		}


    		&-lawnmow {
    			background-image: url('../../global_images/services/mowing-lawn.jpg');
    		}
    		&-edging {
    			background-image: url('../../global_images/services/edging.jpg');
    		}
    		&-trimming {
    			background-image: url('../../global_images/services/bush-trimming.jpg');
    		}
    		&-weeding {
    			background-image: url('../../global_images/services/weeding.jpg');
    		}


    		&-aeration {
    			background-image: url('../../global_images/services/aeration.jpg');
    		}
    		&-fertilization {
    			background-image: url('../../global_images/services/fertilization.jpg');
    		}

    		&-sprinkler {
    			background-image: url('../../global_images/services/sprinkler.jpg');
    		}
    		&-sprinklerrepair {
    			background-image: url('../../global_images/services/sprinkler-repair.jpg');
    		}
    		&-sprinklerrepair {
    			background-image: url('../../global_images/services/sprinkler-repair.jpg');
    		}
    		&-leakingsprinkler {
    			background-image: url('../../global_images/services/fix-leak.jpg');
    		}
    		&-dripline {
    			background-image: url('../../global_images/services/dripline.jpg');
    		}


    		&-palmtreeremoval {
    			background-image: url('../../global_images/services/palm-tree-removal.jpg');
    			background-position: center;
    		}
    		&-palmtreeinstall {
    			background-image: url('../../global_images/services/palmtree.jpg');
    		}


    		&-designedfrontyards {
    			background-image: url('../../global_images/services/landscape.jpg');
    		}
    		&-bushinstall {
    			background-image: url('../../global_images/services/treeinstall.jpg');
    		}
    		&-grassinstall {
    			background-image: url('../../global_images/services/grass.jpg');
    		}
    		&-curbing {
    			background-image: url('../../global_images/services/curbing.jpg');
    		}
    		&-overhauls {
    			background-image: url('../../global_images/services/overhaul.jpg');
    		}
    		&-rockinstalls {
    			background-image: url('../../global_images/services/rock-install.jpg');
    		}
    		&-pathways {
    			background-image: url('../../global_images/services/pathways.jpg');
    		}
    		&-cleanups {
    			background-image: url('../../global_images/services/cleanups.jpg');
    		}


    		&-sidewalk {
    			background-image: url('../../global_images/services/concrete.jpg');
    		}
    		&-stairs {
    			background-image: url('../../global_images/services/stairs.jpg');
    		}

    		&-body {
    			padding: 25px;
			    background: white;
			    text-align: left;
    		}
    		&-title {
    			font-weight: 700;
			    color: gray;
			    font-size: 18px;
			    letter-spacing: 3px;
			    padding-bottom: 10px;
    		}
    		&-description {
    			font-size: 15px;
    			letter-spacing: 0;
    		}

    	}
    }

    .seventhsection {
    	background-color: #404041;
	    background-image: linear-gradient(to right, #48484a 1px, transparent 1px), linear-gradient(to bottom, #48484a 1px, transparent 1px);
    }



}