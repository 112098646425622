.contact {
	#my-recaptcha-placeholder {
		height: 88px;
		padding-bottom: 10px;
	}

	&__body {
	    flex: 1 1 auto;
	    max-width: 1210px;
	    margin: 0 auto;
	    display: flex;
	    justify-content: space-between;
	    padding: 0;

	    &-landscape {
			border-bottom: 1px solid #ccc;

	    	@media only screen and (max-width: 768px) {
				padding-bottom: 20px;
			}
	    	padding-bottom: 10px;
	    	h1 {
	    		font-weight: 800;
	    		font-size: 35px;
	    		color: #7F7F7F;
	    		margin-bottom: 20px;
	    		@media only screen and (max-width: 768px) {
					text-align: center;
					margin-top: 0;
					font-size: 25px;
				}
	    	}
	    	&-content {
	    		font-size: 17px;
	    		font-weight: 300;
	    		line-height: 26px;
	    		img {
	    			width: 200px;
	    			float: left;
	    			margin-right: 15px;
	    			padding-top: 5px;
	    			height: 125px;
	    			border-radius: 100px;
	    		}
	    		padding-bottom: 20px;
	    	}

	    	&-form {
	    		padding-right: 15px;
	    		padding: 20px;
	    		padding-top: 10px;
	    		border-radius: 4px;
	    		label {
	    			display: block;
	    			color: gray;
	    			font-weight: 400;
	    			display: none;
	    		}
	    		input {
	    			border: 1px solid #c7c7c7;
				    width: 100%;
				    padding: 10px;
				    padding-left: 20px;
				    margin-bottom: 20px;
				    border-radius: 3px;
				    height: 50px;
				    font-size: 18px;
				    @media only screen and (max-width: 770px) {
						font-size: 14px;
				    	height: 40px;
				    	padding-left: 15px;
					}
	    		}
	    		textarea {
	    			width: 100%;
	    			height: auto;
	    			border: 1px solid #c7c7c7;
	    			padding: 10px;
				    padding-left: 20px;
				    margin-bottom: 20px;
				    border-radius: 3px;
				    font-size: 18px;
				    @media only screen and (max-width: 770px) {
						font-size: 14px;
				    	padding-left: 15px;
					}
	    		}
	    		&-col {
	    			padding-right: 0;
	    		}
	    		&-row {
	    			padding-right: 15px;
	    		}
	    		&-policy {
	    			color: gray;
	    			font-weight: 100;
	    			padding-bottom: 20px;
	    		}
	    		&-contact-btn {
	    			height: 40px;
				    padding: 10px 25px 15px 25px;
				    margin: 0 auto;
				    text-align: center;
				    background: #afb36d;
				    color: white;
				    @media only screen and (max-width: 768px) {
						width: 100%;
						display: block;
						.emerald-button-content {
							display: block;
						}
					}
	    		}
	    		&-contact-btn-loading, &-contact-btn-disabled {
	    			background: #c1c39e;
	    		}
	    		&-phone {
	    			img {
	    				display: none;
	    			}
	    			select { 
	    				display: none;
	    			}
	    		}
	    		&-error {
	    			color: red;
	    			padding-top: 4px;
	    		}
	    		&-success {
	    			color: #424242;
				    letter-spacing: 0.1px;
				    padding: 15px;
				    background-color: #38883221;
				    border-radius: 5px;
				    margin-top: 10px;
	    		}
	    	}
    		clear: both;
	    }
	}

	&-bigbutton {
		margin-top: 10px;
		width: 100%;
		background: #b7bb7a;
		text-align: center;
		color: white;
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 20px;

		&-disabled {
			cursor: not-allowed;
			opacity: 0.7;
		}

		&-enabled {
			cursor: pointer;
		}

		&-enabled:hover {
			opacity: 0.9;
		}

		&-enabled:active {
		    transform: translateY(1px);
		}

		&-loading {
		 	opacity: 0.7;
		 	cursor: not-allowed;
		}
	}
}