#sidemenu {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 400px;
  max-width: 100vw;
  transition: right ease-in-out 400ms;
  background: #F2F1F2;
  color: #F4F4F4;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 1;
  top: 60px;
  overflow-y: auto; 
  @media only screen and (min-width: 771px) {
    display: none;
  }

}
  #sidemenu-topbar {
    height: 60px;
    background-color: #404040;
    position: fixed;
    width: 400px;
    max-width: 100%;
    z-index: 3;
  }

  #sidemenu-topbar-phone {
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 0.3px;
    position: relative;
    top: 18px;
    padding-left: 20px;
  } 
  #sidemenu-topbar-phone-text {
    padding-left: 10px;
    color: white;
  }
  #sidemenu ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important; }
  #sidemenu.sidemenu-open {
    right: 0px; }
  #sidemenu.sidemenu-closed {
    right: -405px; }
  #sidemenu .sidemenu-list {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; }
  #sidemenu .sidemenu-item {
    font-weight: 300;
    padding-left: 35px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 20px;
    display: block; 
    width: 90%;
  }
  #sidemenu .sidemenu-item.sidemenu-item-emphasized {
    font-weight: 700; 
  }
  #sidemenu .sidemenu-item-main {
    font-weight: 600;
    color: #595959;
    letter-spacing: 1px;
  }
  #sidemenu .sidemenu-item-sub {
    padding-left: 55px;
    font-weight: 400;
    color: #989898;
    font-size: 16px;
    letter-spacing: 0.7px;
  }
  #sidemenu .sidemenu-header {
    padding-bottom: 20px;
    padding-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 2px solid #C2C2C2;
    text-align: left; }
  #sidemenu .sidemenu-header-logo {
    width: 150px;
    height: 118px;
    background-image: url('../../global_images/menu-logo.png');
    background-size: cover;
    cursor: pointer;
  }
  #sidemenu .logo-wrapper {
    position: absolute;
    bottom: 10px;
    left: 10px;
    opacity: .45;
    display: inline-block;
    padding: 10px;
    z-index: -1; }
    #sidemenu .logo-wrapper img {
      transition: opacity 200ms ease-in-out;
      transform: rotateZ(-13deg);
      position: relative;
      left: 5px;
      top: 2px; }
  #sidemenu .tr-logo {
    height: 40px; }
  #sidemenu .sidemenu-exit-btn {
    cursor: pointer;
    position: absolute;
    top: 18px;
    right: 15px;
    font-size: 14px;
  }
  #sidemenu .sidemenu-exit-btn .glyphicon {
    font-size: 20px;
  }
  #sidemenu .sidemenu-exit-btn:hover {
    color: #e67878;
  }
  #sidemenu a {
    z-index: 2; 
  }
  #sidemenu a.sidemenu-link {
    text-decoration: none;
  }
  .sidemenu-item-main-divider {
    width: 90%;
    height: 1px;
    background: #e0e0e0;
    text-align: center;
    margin: 0 auto;
  }
  #sidemenu a.sidemenu-link:hover {
    color: #579e71;
  }
  .sidemenu-quote-btn {
    width: 100%;
    padding-bottom: 30px;
    padding-top: 10px;
  }
  .sidemenu-quote-btn .greenbutton {
    margin: 0 auto;
    width: 220px;
  }

// #sidemenu::-webkit-scrollbar {
//   width: 5px;
// }

// /* Track */
// #sidemenu::-webkit-scrollbar-track {
//   background: #f1f1f1; 
// }

// /* Handle */
// #sidemenu::-webkit-scrollbar-thumb {
//   background: #888; 
// }

// /* Handle on hover */
// #sidemenu::-webkit-scrollbar-thumb:hover {
//   background: #555; 
// }