.fifthsection {
  width: 100%;
  background-size: 10px 10px;
  background-image: linear-gradient(to right, #f3f3f3 1px, transparent 1px), linear-gradient(to bottom, #f3f3f3 1px, transparent 1px);
  padding-bottom: 30px;
  padding-top: 60px;


  &__map {
      text-align: center;
      img {
        width: 55px;
      }
    }

    &__header {
      text-align: center;
      h1 {
        color: #595959;
        font-weight: 900;
        font-size: 31px;
        padding-left: 25px;
        padding-right: 25px;
        @media only screen and (max-width: 770px) {
          font-size: 25px;
        }
      }
    }

    &__subheader {
      color: #313131;
      text-align: center;
      margin: 0 auto;
      font-size: 20px;
      max-width: 690px;
      padding-top: 10px;
      padding-left: 30px;
      padding-right: 30px;
      font-weight: 300;
      line-height: 25px;
      @media only screen and (max-width: 770px) {
        font-size: 18px;
      }
    }

    &__googlemap {
      max-width: 1600px;
      margin: 0 auto;
      padding-bottom: 10px;
    }

}