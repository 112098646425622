.privacypage {
	background-size: 10px 10px;
    background-image: linear-gradient(to right, #f3f3f3 1px, transparent 1px), linear-gradient(to bottom, #f3f3f3 1px, transparent 1px);

	&__banner {
		background-image: linear-gradient(rgba(0, 0, 0, .65) 0%, rgba(0, 0, 0, 0) 40%), url('../../global_images/grassbanner.jpg');
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 300px;
		width: 100%;
		padding-top: 150px;
		@media only screen and (max-width: 770px) {
			padding-top: 0px;
		}


		&-logo {	
			@media only screen and (min-width: 771px) {
				display: none;
			}
			text-align: center;
			margin: 0 auto;
			img {
				margin-top: 80px;
				width: 140px;
                cursor: pointer;
			}
		}
	}

	&__buttons {
    	padding-top: 30px;
    	text-align: center;
    	@media only screen and (max-width: 770px) {
    		padding-top: 20px;
		}
    	.commonbutton {
    		display: inline-block;
    		width: 300px;
    		margin-left: 12px;
    		margin-right: 12px;
    		margin-bottom: 25px;
    		@media only screen and (max-width: 400px) {
    			width: 250px;
    		}
    	}
    }

    &__body {
    	max-width: 1200px;
    	padding-top: 80px;
    	margin: 0 auto;
    	padding-left: 40px;
    	padding-right: 40px;
        line-height: 35px;
        font-size: 20px;
    	@media only screen and (max-width: 770px) {
    		padding-top: 50px;
    	}
    }

}