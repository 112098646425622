.sixthsection {
    width: 100%;
    background-image: url('./images/background1.jpg');
    padding-top: 80px;
    padding-bottom: 50px;

	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

    &__subheader {
    	color: white;
	    text-align: center;
	    margin: 0 auto;
	    font-size: 18px;
	    font-style: italic;
	    padding-top: 10px;
	    padding-left: 30px;
	    padding-right: 30px;
	    @media only screen and (max-width: 770px) {
			font-size: 16px;
		}
    }

    &__header {
    	text-align: center;
    	h1 {
    		color: #F2F2F2;
		    margin-top: 20px;
		    font-weight: 600;
		    font-size: 33px;
		    @media only screen and (max-width: 770px) {
				font-size: 27px;
				padding-left: 40px;
				padding-right: 40px;
			}
    	}
    }

    &__facts {
    	max-width: 700px;
    	margin: 0 auto;
    	padding-top: 30px;
    	padding-bottom: 20px;
    	&-single {
    		color: white;
		    border: 1px solid white;
		    padding: 15px 0px 15px 0px;
		    text-align: center;
		    margin: 0 auto;
		    margin-bottom: 30px;
		    width: 320px;
		    @media (max-width: 370px){
		    	width: 290px;
		    }
    	}
    	@media (max-width: 830px){
			.row {
				margin-right: 0px;	
				margin-left: 0px;	
			}
		}
    }

    .commonbutton {
    	margin: 0 auto;
    	width: 275px;
    }
}