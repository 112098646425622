.contactpage {
    background-size: 10px 10px;
    background-image: linear-gradient(to right, #f3f3f3 1px, transparent 1px), linear-gradient(to bottom, #f3f3f3 1px, transparent 1px);

  &__banner {
    background-image: linear-gradient(rgba(0, 0, 0, .65) 0%, rgba(0, 0, 0, 0) 40%), url('../../global_images/grassbanner.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    width: 100%;
    padding-top: 150px;
    @media only screen and (max-width: 770px) {
      padding-top: 0px;
    }


    &-logo {  
      @media only screen and (min-width: 771px) {
        display: none;
      }
      text-align: center;
      margin: 0 auto;
      img {
        margin-top: 80px;
        width: 140px;
                cursor: pointer;
      }
    }
  }

  &__buttons {
      padding-top: 30px;
      text-align: center;
      @media only screen and (max-width: 770px) {
        padding-top: 20px;
    }
      .commonbutton {
        display: inline-block;
        width: 300px;
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 25px;
        @media only screen and (max-width: 400px) {
          width: 250px;
        }
      }
    }

    &__body {
      max-width: 1200px;
      padding-top: 80px;
      margin: 0 auto;
      padding-left: 40px;
      padding-right: 40px;
      @media only screen and (max-width: 770px) {
        padding-top: 50px;
      }
    }


    &__freequote {
      padding-bottom: 50px;
      &-icon {
        text-align: center;
        img {
          width: 35px;
          display: inline-block;
          vertical-align: sub;
          margin-right: 13px;
        }
        h1 {
          display: inline-block;
          font-weight: 900;
          color: #4e4e4e;
          text-align: center;
          padding-bottom: 10px;
          margin-top: 0;
        }
        div {
          display: inline-block;
        }
      }

      &-subheader {
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #4e4e4e;
        letter-spacing: 0.2px;
        text-align: center;
        line-height: 23px;
      }

      &-body {
        .greenbutton {
          margin: 0 auto;
          width: 250px;
          margin-bottom: 20px;
        }
        .contact {
          background: #f1f1f1;
          padding-top: 10px
        }
      }
    }

    &__map {
      padding-bottom: 50px;
      &-icon {
        text-align: center;
        img {
          width: 35px;
          display: inline-block;
          vertical-align: sub;
          margin-right: 13px;
        }
        h1 {
          display: inline-block;
          font-weight: 900;
          color: #4e4e4e;
          text-align: center;
          padding-bottom: 10px;
          margin-top: 0;
        }
        div {
          display: inline-block;
        }
      }

      &-subheader {
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #4e4e4e;
        letter-spacing: 0.2px;
        text-align: center;
        line-height: 23px;
      }

      &-body {
        input {
            margin-top: 0;
          }
      }
    }

    &__specialoffers {
      padding-bottom: 50px;
      &-icon {
        text-align: center;
        img {
          width: 35px;
          display: inline-block;
          vertical-align: sub;
          margin-right: 13px;
        }
        h1 {
          display: inline-block;
          font-weight: 900;
          color: #4e4e4e;
          text-align: center;
          padding-bottom: 10px;
          margin-top: 0;
        }
        div {
          display: inline-block;
        }
      }

      &-subheader {
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #4e4e4e;
        letter-spacing: 0.2px;
        text-align: center;
        line-height: 23px;
      }

      &-body {
        text-align: center;
        padding-top: 10px;
        &-offer {
          max-width: 275px;
          margin-left: 15px;
          margin-right: 15px;
          margin-bottom: 15px;
          display: inline-block;;
          vertical-align: top;
        }
      }
    }

    &__joinus {
      padding-bottom: 50px;
      &-icon {
        text-align: center;
        img {
          width: 35px;
          display: inline-block;
          vertical-align: sub;
          margin-right: 13px;
        }
        h1 {
          display: inline-block;
          font-weight: 900;
          color: #4e4e4e;
          text-align: center;
          padding-bottom: 10px;
          margin-top: 0;
        }
        div {
          display: inline-block;
        }
      }

      &-subheader {
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #4e4e4e;
        letter-spacing: 0.2px;
        text-align: center;
        line-height: 23px;
      }

      &-body {
        &-buttons {
          text-align: center;
        }
        .commonbutton {
          display: inline-block;
          width: 250px;
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 20px;
        }
        .whitebutton {
          border: 1px solid #cacaca;
        }
      }

      &-description {
        animation: slide-up 0.5s ease;
        position: absolute;
        background: white;
        padding: 35px 30px 30px 30px;
        z-index: 99;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0px 9px 26px -12px rgba(0,0,0,0.54);
        max-width: 400px;
        &-closebtn {
          position: absolute;
          right: 12px;
          top: 12px;
          font-size: 17px;
          color: #b7b7b7;
          span {
            cursor: pointer;
          }
          span:hover {
            color: black;
          }
        }
        h2 {
          font-weight: 900;
          color: #5f5f5f;
          margin-bottom: 10px;
          margin-top: 0px;
        }
        h4 {
          font-weight: 700;
          color: #2e7b4b;
          margin-bottom: 0;
        }
        h5 {
          margin-top: 3px;
          font-weight: 700;
          letter-spacing: 0.5px;
          color: #969696;
        }
        &-status {
          padding: 3px;
          background-color: #5d986b;
          text-align: center;
          color: white;
          font-weight: 700;
          margin-bottom: 12px;
          span {
            padding-right: 6px;
            @media only screen and (max-width: 500px) {
              display: none;
            }
          }
          div {
            display: inline-block;
          }
          &-requirements {
            font-weight: 700;
          }
        }
      }

      &-apply {
        animation: slide-up 0.5s ease;
        position: absolute;
        background: white;
        padding: 35px 30px 30px 30px;
        z-index: 99;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0px 9px 26px -12px rgba(0,0,0,0.54);
        max-width: 400px;
        input {
          border: 1px solid #c7c7c7;
          width: 100%;
          padding: 10px;
          padding-left: 20px;
          margin-bottom: 10px;
          border-radius: 3px;
          height: 50px;
          font-size: 18px;
        }
        &-closebtn {
          position: absolute;
          right: 12px;
          top: 12px;
          font-size: 17px;
          color: #b7b7b7;
          span {
            cursor: pointer;
          }
          span:hover {
            color: black;
          }
        }
        h2 {
          font-weight: 900;
          color: #5f5f5f;
          margin-bottom: 10px;
          margin-top: 0px;
          text-align: center;
        }
        h4 {
          font-weight: 700;
          color: #2e7b4b;
          margin-bottom: 0;
          text-align: center;
        }
        h5 {
          margin-top: 3px;
          font-weight: 700;
          letter-spacing: 0.5px;
          color: #969696;
          text-align: center;
          margin-bottom: 15px;
        }
        &-email-description {
          text-align: center;
          font-weight: 700;
          color: grey;
          padding-bottom: 10px;
        }
        &-resume-description {
          text-align: center;
          padding-top: 20px;
          padding-bottom: 10px;
          padding-left: 10px;
          word-break: break-all;
          font-size: 16px;
          span {
            padding-right: 5px;
          }
        }
        &-remove-doc {
          cursor: pointer;
          position: relative;
          top: 2px;
          margin-left: 8px;
        }
        &-remove-doc:hover { 
          opacity: 0.9;
        }
        &-resume-error {
          color: red;
          text-align: center;
        }
        &-divider {
          width: 87%;
          height: 1px;
          background: #cacaca;
          margin: 0 auto;
          margin-top: 10px;
          margin-bottom: 19px;
        }
        img {
          display: none;
        }
        select { 
          display: none;
        }
        input[type='file'] {
          display: none;
        }
        &-success {
          font-weight: 700;
          color: green;
          text-align: center;
          font-size: 15px;
          margin-bottom: 10px;
        }
        &-faliure {
          color: red;
          text-align: center;
          font-size: 15px;
          margin-bottom: 10px;
        }
        &-closetext {
          font-weight: 700;
          color: #8a8a8a;
          border-bottom: 1px solid #3c3c3c;
          width: 41px;
          margin: 0 auto;
          cursor: pointer;
          text-align: center;
        }
        &-closetext:hover {
          opacity: 0.8;
        }
      }
    }

    &__writeareview {
      padding-bottom: 50px;
      &-icon {
        text-align: center;
        img {
          width: 30px;
          display: inline-block;
          vertical-align: sub;
          margin-right: 13px;
        }
        h1 {
          display: inline-block;
          font-weight: 900;
          color: #4e4e4e;
          text-align: center;
          padding-bottom: 10px;
          margin-top: 0;
        }
        div {
          display: inline-block;
        }
      }

      &-subheader {
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #4e4e4e;
        letter-spacing: 0.2px;
        text-align: center;
        line-height: 23px;
      }

      &-body {
        text-align: center;
      }

      &-btn {
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        img {
          width: 150px;
        }
      }

    }
}