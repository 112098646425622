.seventhsection {
	background-color: #505F56;
    width: 100%;
    background-size: 10px 10px;
    background-image: linear-gradient(to right, #586b60 1px, transparent 1px), linear-gradient(to bottom, #586b60 1px, transparent 1px);
    padding-top: 60px;
    padding-bottom: 60px;

    &__tree {
    	text-align: center;
    	img {
    		width: 55px;
    	}
    }

    &__header {
    	text-align: center;
    	h1 {
    		color: #F2F2F2;
		    margin-top: 20px;
		    font-weight: 600;
		    font-size: 33px;
            padding-left: 30px;
            padding-right: 30px;
		    @media only screen and (max-width: 770px) {
				font-size: 25px;
			}
    	}
    }

    &__subheader {
    	color: white;
	    text-align: center;
	    margin: 0 auto;
	    font-size: 18px;
	    max-width: 690px;
	    padding-top: 10px;
	    padding-left: 30px;
	    padding-right: 30px;
	    font-weight: 400;
	    line-height: 25px;
    }

    &__buttons {
    	padding-top: 30px;
    	text-align: center;
    	.commonbutton {
    		display: inline-block;
    		padding-left: 100px;
    		padding-right: 100px;
    		margin-left: 12px;
    		margin-right: 12px;
    		margin-bottom: 25px;
    		@media only screen and (max-width: 770px) {
				width: 250px;
                padding-left: 0;
                padding-right: 0;
			}
    	}
    	.greenbutton {
    		border: 1px solid white;
    	}
    }

    &__calcbutton {
    	padding-top: 25px;
    	.commonbutton {
    		margin: 0 auto;
            width: 350px;
            @media only screen and (max-width: 770px) {
                width: 250px;
                padding-left: 0;
                padding-right: 0;
            }
    	}
    }
}