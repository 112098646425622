.google-maps-component {
  position: relative;
  padding-bottom: 20px;

  &__check-location {
    color: white;
    text-align: center;
    // padding-top: 10px;
    padding-bottom: 20px;
    @media only screen and (max-width: 560px) {
      font-size: 15px;
    }
  }

  &__check-location-input {
    background-color: white;
    text-align: center;
    font-weight: 100;
    font-size: 17px;
    color: black;
    height: 46px;
    padding: 14px;
    margin-top: 20px;
    width: 350px;
    border-radius: 0;
    border-top-left-radius: 42px;
    border-bottom-left-radius: 42px;
    border: 2px solid #2e7b4b;
    @media only screen and (max-width: 560px) {
      width: 250px;
    }
    @media only screen and (max-width: 770px) {

    }
  }

  &__check-location-input:focus {
    outline: none;
    box-shadow: 0px 0px 2px blue;
  }

  &__valid {
    text-align: center;
    color: black;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 18px;
    span {
      color: #4ec14d;
      padding-right: 4px;
      padding-bottom: 10px;
    }
    &-link {
      a { 
        color: #0e820e;
        letter-spacing: 0.3px;
        border-bottom: 1px solid #10820e;
        padding-bottom: 1px;
        text-decoration: none;
        font-weight: 600;
      }
      a:hover {
        text-decoration: none;
        opacity: 0.9;
      }
      a:visited {
        text-decoration: none;
      }
      a:active {
        text-decoration: none;
      }
    }
  }

  &__invalid {
    color: black;
    text-align: center;
    font-weight: 100;
    font-size: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 18px;
    font-weight: 400;
    a {
      color: blue;
      font-weight: 400;
      text-decoration: underline;
    }
  }

  &__error {
    color: red;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 18px;
    text-align: center;
  }

  .commonbutton {
    font-weight:400;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: inline-block;
    font-size: 13px;
    padding: 14px;
    vertical-align: bottom;
  }

}

.google-maps-contact-now {
  display: inline-block;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.google-maps-contact-now:hover {
  opacity: 0.8;
}