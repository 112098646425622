.desktopmenu {
	position: fixed;
	width: 100%;
	padding: 0;
	margin: 0;
	transition: background 0.4s;
	z-index: 9999;

	ul {
		// margin: 0;
		padding: 0;
		li {
			list-style-type: none;
		}
	}

	&__logo {
		img {
			width: 135px;
		    padding-top: 25px;
		    transition: width 0.4s, padding-top 0.4s;
		    cursor: pointer;
		    z-index: 2;
		}
		img:hover {
			opacity: 0.8;
		}
	}

	&__trapezoid-div {
		position: relative;
		width: 100%;
		z-index: -1;		
		opacity: 0;
		transition: opacity 0.4s;
		@media only screen and (max-width: 770px) {
			display: none;
		}

	}

	&__trapezoid {
		position: absolute;
		text-align: center;
		margin: 0 auto;
		border-top: 34px solid #000000d6;
		border-left: 35px solid transparent;
		border-right: 35px solid transparent;
		height: 0;
		width: 200px;

		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}
}

.desktopmenu-background {
	background: #000000d6;
	.desktopmenu__logo {
		img {
			width: 98px;
			padding-top: 14px;
		}
	}
	#main-nav {
		height: 72px;
	}
	.main-nav-li-main {
		padding-top: 27px;
	}
	.desktopmenu__trapezoid-div {
		opacity: 1;
	}
	.main-nav-li {
		height: 73px;
		.commonbutton {
			margin-top: 14px;
		}
	}

}

#main-nav {
	max-width: 1280px;
	margin: 0 auto;
	height: 130px;
	transition: height 0.4s;

	@media only screen and (max-width: 900px) {
		font-size: 12px;
	}

	@media only screen and (max-width: 770px) {
		display: none;
	}
}

#main-nav-2 {
	z-index: 99;
	background: #377750;
    height: 60px;
    color: white;
    border-bottom: 1px solid #909090;
	@media only screen and (min-width: 771px) {
		display: none;
	}

	.glyphicon-earphone {
		padding-right: 10px;
	}

	&-phone {
		display: inline-block;	
		text-decoration: none;
		color: white;
		font-weight: 600;
	    font-size: 17px;
	    letter-spacing: 0.3px;
	    position: relative;
	    top: 18px;
	    padding-left: 20px;

	    &-text {
	    	color: white;
	    }
	}

	&-menu { 
		display: inline-block;
		position: relative;
	    top: 18px;
	    float: right;
	    padding-right: 20px;
	    font-size: 18px;
	    // cursor: pointer;
	    .glyphicon {
	    	cursor: pointer;
	    }
	    .glyphicon:hover {
	    	opacity: 0.8;
	    }
	    .glyphicon:active {
	    	opacity: 1;
	    }
	    
	}

	clear: both;
}

#main-nav a {
	color: white;
	display: block;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}

.main-nav-li a {
	padding-top: 63px;
	letter-spacing: 2.5px;
	transition: padding-top 0.4s;
}

.main-nav-li {
	height: 120px;
	.commonbutton {
	    margin-top: 49px;
	    padding-left: 25px;
	    padding-right: 25px;
	    font-size: 13px;
	    transition: margin-top 0.4s;
	    @media only screen and (max-width: 946px) {
			padding-left: 13px;
		    font-size: 12px;
		    padding-right: 13px;
		}

	}
}

.main-nav-li-highlighted {
	color: #5ad871;
    font-weight: 700
}

.submenu a {
	padding-top: 1000px;
}

#main-nav a:hover {
	color: #dedede;
}

#main-nav ul {
	display: flex;
	list-style: none;
}

#main-nav li {
	width: 100%;
	text-align: center;
	position: relative;
}

#main-nav li:hover .submenu > li {
	display: block;
	top: 0px;
}

.submenu li {
	display: none; 
	position: absolute;
	top: 0px;
    padding-left: 18px;
    padding-right: 18px;
    text-align: left;
}

.submenu li:hover {
    background:#668a6ad9;
}

.submenu {
	display: flex;
	flex-direction: column;
	position: absolute;
	background: #ffffffde;
    margin-top: 25px;
    box-shadow: -4px 8px 30px -13px rgba(0,0,0,0.54);
    a {
    	    color: black !important;
    	    border-bottom: 1px solid #afafaf;
    	    padding: 10px;
    	    font-weight: 400 !important;
    	    letter-spacing: 1px !important;
    }
    li:last-child {
    	a {
    		    	border-bottom: 0;
    	}
    }
}

.arrow { 
	margin-left: 8px;
	color: white;
	font-size: 12px;
}