.review {
	position: relative;
    margin: 0 auto;
    @media only screen and (max-width: 767px) {
    	 width: 300px;
    }
    @media only screen and (max-width: 370px) {
    	 width: 200px;
    }

	&__profile-pic {
		position: absolute;
    	top: -28px;
    	left: -28px;
    	img {
    		width: 60px;
    		border-radius: 50%;
    	}
	}

	a {
		text-decoration: none;
	}

	&__box {
		max-width: 300px;
	    background-color: #0000006b;
	    padding: 15px;
	    padding-left: 28px;
	    padding-right: 28px;
	    border-radius: 20px;
	    color: white;
	    text-align: center;
	    font-style: italic;
	    letter-spacing: 0.2px;
	    font-size: 17px;
	    line-height: 21px;

	    @media only screen and (max-width: 767px) {
	    	font-size: 14px;
		    line-height: 19px;
	    }

	    &-content {
	    	padding-bottom: 10px;
	    }
	}

}

.review:hover {
	opacity: 0.9;
}